import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { CardHeader, Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import CardActions from '@material-ui/core/CardActions';
import { useApolloClient } from '@apollo/client';
import ActionMenu from '../../../components/common/ActionMenu';

const styles = (theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardGrid: {
    padding: `${theme.spacing.unit * 8}px 0`,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    transition: 'filter 1s',

  },
  cardHeader: {
    alignItems: 'flex-start',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
    position: 'relative',

  },
  cardContent: {
    flexGrow: 1,
    paddingTop: 0,
  },
  tag: {
    backgroundColor: '#85d7a9',
    paddingRight: 10,
    paddingLeft: 10,
    marginLeft: 16,
    position: 'absolute',
    bottom: 5,
    left: 0,
    borderRadius: 10,
  },
});

const ServiceCard = (props) => {
  const {
    service, title, subtitle, price, location, bookingLink, duration, image, company, edit,
  } = props;
  const [active, setActive] = React.useState(props.active);
  const { classes } = props;
  const client = useApolloClient();



  return (
    <Card
      className={classes.card}
      style={{ filter: active ? 'grayscale(0%)' : 'grayscale(100%)' }}
    >

      <CardMedia
        className={classes.cardMedia}
        image={image}
        title={title}
        src=""
      >
        <div className={classes.tag}>
          <Typography>
            {location}
          </Typography>
        </div>
      </CardMedia>

      <CardHeader
        className={classes.cardHeader}
        action={<ActionMenu card={service} company={company} service />}
        title={title}
        subheader={`${price} Sheqels`}
      />

      <CardContent className={classes.cardContent}>
        <Typography> {subtitle}</Typography>
      </CardContent>
      <CardActions style={{ padding: 8 }}>
        {!edit && <Switch onChange={handleSwitch} color="primary" checked={!!active} />}
        {!edit && (
          <Typography color="primary" variant="caption">
            {active ? 'Active' : 'Inactive'}
          </Typography>
        )}

      </CardActions>
    </Card>
  );
};

export default withStyles(styles)(ServiceCard);
