import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import CreateDialog from '../../../components/common/CreateDialog';
import CurrentCompanyContext from '../../../containers/CurrentCompany/CurrentCompanyContext';
import ServiceCard from './ServiceCard';

const styles = (theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  header: {
    display: 'flex',
    textAlign: 'center',
    width: '100%',
    marginBottom: 32,
    justifyContent: 'center',
    position: 'relative',
  },
});

const Services = ({ classes, services, company, edit = true, }) => {
  const { currentCompany } = React.useContext(CurrentCompanyContext);

  return (
    <div className={classes.grid}>
      <div className={classes.header}>
        {edit && <CreateDialog type="service" action="create" company={currentCompany._id} />}
        <Typography gutterBottom variant="h4">
          {`${!!currentCompany && currentCompany.name} Services`}
        </Typography>
      </div>
      <Grid container spacing={2}>
        {services.map((service, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} lg={4}>
            <ServiceCard
              company={currentCompany}
              service={service}
              title={service.title}
              subtitle={service.subtitle}
              price={service.price}
              duration={service.duration}
              image={service.image}
              location={service.location}
              bookingLink={service.bookingLink}
              id={service._id}
              edit={edit}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Services);
